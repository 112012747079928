import React, { useState, useEffect } from "react";
import DropDown from "./DropDown";
import FiltersList from "./FiltersList";
import "../css/modal.css";
import "../css/DisplayTable.css";

const URL = process.env.REACT_APP_API_URL;

const DisplayTable = ({ table, active, onUpdateLoading }) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [filters, setFilters] = useState({});

  // Updates filter list with children selected checkboxes
  function updateFilterList(childName, selectedCheckboxes) {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [childName]: selectedCheckboxes,
    }));

    if (selectedCheckboxes.length === 0) {
      delete filters[childName];
    }
  }

  const getFilteredTable = async () => {
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/tables/${table}/filter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      });

      if (!response.ok) {
        setFilters({});
        throw new Error("Failed to fetch filtered data");
      }

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.error("Error fetching filtered data: ", error);
    } finally {
      onUpdateLoading(false);
    }
  };

  // Returns coincidences from current data and filter data
  async function applyFilter(newFilters) {
    try {
      const response = await fetch(`${URL}/tables/apply/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newFilters),
      });

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
      setFilters({});
    } catch (error) {
      console.error("Error saving filters: ", error);
    }
  }

  // Deletes coincidences from current data and filter data
  async function removeFilter(newFilters) {
    try {
      const response = await fetch(`${URL}/tables/remove/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newFilters),
      });

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
      setFilters({});
    } catch (error) {
      console.error("Error saving filters: ", error);
    }
  }

  // Saves in the db a new filter
  async function saveFilter() {
    const newName = prompt("Nombre del filtro:");
    try {
      const filterData = {
        name: newName,
        filters: filters,
      };

      const response = await fetch(`${URL}/filters/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filterData),
      });

      if (response.ok) {
        console.log("Filters saved successfully");
      } else {
        console.error("Error saving filters");
      }
    } catch (error) {
      console.error("Error saving filters: ", error);
    }
  }

  // Updates a filter with the current filter data
  async function updateFilter(id, name) {
    const newName = prompt("Introduce el nuevo nombre para el filtro:", name);
    try {
      const filterData = {
        name: newName,
        filters: filters,
      };

      const response = await fetch(`${URL}/filters/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filterData),
      });

      if (response.ok) {
        console.log("Filters updated successfully");
      } else {
        console.error("Error updating filters");
      }
    } catch (error) {
      console.error("Error updating filters: ", error);
    }
  }

  // Asks backend to generate a csv and downloads it
  async function exportCsv() {
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/export/csv/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      });

      if (!response.ok) {
        throw new Error("Error al exportar CSV");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `exported_${table}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el CSV:", error);
    }
    onUpdateLoading(false);
  }

  // Only execute when rendering component - Loads base table
  useEffect(() => {
    const getTable = async () => {
      onUpdateLoading(true);
      try {
        const response = await fetch(`${URL}/tables/${table}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const { data, meta } = await response.json();
        setData(data);
        setMeta(meta);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        onUpdateLoading(false);
      }
    };
    if (active) getTable(); // eslint-disable-next-line
  }, []);

  // ---------- //
  return (
    <div>
      <div>
        <FiltersList
          onApplyFilter={applyFilter}
          onRemoveFilter={removeFilter}
          onSaveFilter={saveFilter}
          onUpdateFilter={updateFilter}
          onShowFilter={setFilters}
        />
      </div>
      <div>
        <button onClick={getFilteredTable}>Filtrar</button>
        <button onClick={() => setFilters({})}>Reset</button>
      </div>
      {data && data.length !== 0 ? (
        <div>
          <h2>{table}</h2>
          <label>({meta} lineas)</label>
          <button onClick={exportCsv}>Exportar</button>
          <table>
            <thead>
              <tr>
                {Object.keys(data[0]).map((key) => (
                  <th key={key}>
                    <DropDown
                      tableName={table}
                      columnName={key}
                      checkboxes={filters[key] || []}
                      onUpdateFilter={updateFilterList}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {Object.values(item).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No hay datos para mostrar</p>
      )}
    </div>
  );
};

export default DisplayTable;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-main {
    display: inline-block;
    width: max-content;
}

.dropdown-all {
    display: grid;
    grid-template-columns: 2fr auto;
    align-items: center;
    justify-items: center;
    border-top: 1px solid black;
    margin-top: 0.25rem;
    padding-top: 0.25rem;
}

.dropdown-checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    align-items: center;
    justify-items: center;
    gap: 2px;
}

.confirm {
    border-top: 1px solid black;
    margin-top: 0.25rem;
    padding-top: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/css/DropDown.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,+BAA+B;IAC/B,mBAAmB;IACnB,qBAAqB;IACrB,2BAA2B;IAC3B,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mCAAmC;IACnC,mBAAmB;IACnB,qBAAqB;IACrB,QAAQ;AACZ;;AAEA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[".dropdown-main {\n    display: inline-block;\n    width: max-content;\n}\n\n.dropdown-all {\n    display: grid;\n    grid-template-columns: 2fr auto;\n    align-items: center;\n    justify-items: center;\n    border-top: 1px solid black;\n    margin-top: 0.25rem;\n    padding-top: 0.25rem;\n}\n\n.dropdown-checkboxes {\n    display: grid;\n    grid-template-columns: 1fr 1fr auto;\n    align-items: center;\n    justify-items: center;\n    gap: 2px;\n}\n\n.confirm {\n    border-top: 1px solid black;\n    margin-top: 0.25rem;\n    padding-top: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

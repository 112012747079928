import React, { useEffect, useState, useCallback } from "react";

const URL = process.env.REACT_APP_API_URL;

const FiltersList = ({
  onApplyFilter,
  onRemoveFilter,
  onSaveFilter,
  onUpdateFilter,
  onShowFilter,
}) => {
  const [filters, setFilters] = useState([]);

  // Carga los filtros almacenados en la db
  const fetchFilters = useCallback(async () => {
    try {
      const response = await fetch(`${URL}/filters/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch filters");
      }
      const data = await response.json();
      setFilters(data);
    } catch (error) {
      console.log("Error fetching filters: ", error);
    }
  }, []);

  // Guarda un nuevo filtro y actualiza la lista de filtros
  const saveFilter = async () => {
    await onSaveFilter();
    fetchFilters();
  };

  // Modifica un filtro en la base de datos
  const updateFilter = async (id) => {
    const filterName = filters.find((filter) => filter.id === id).name;
    await onUpdateFilter(id, filterName);
    fetchFilters();
  };

  // Borra un filtro de la base de datos
  const deleteFilter = async (id) => {
    try {
      const response = await fetch(`${URL}/filters/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete filter");
      }
      fetchFilters();
      console.log("Filter deleted successfully");
    } catch (error) {
      console.log("Error deleting filter: ", error);
    }
  };

  // Obtiene los datos de un filtro de la db
  const getFilter = async (id) => {
    try {
      const response = await fetch(`${URL}/filters/get/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch filter");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error fetching filter: ", error);
      return null;
    }
  };

  // Aplica un filtro
  async function applyFilter(id) {
    const filterData = await getFilter(id);
    if (filterData) onApplyFilter(filterData);
  }

  // Quita un filtro
  async function removeFilter(id) {
    const filterData = await getFilter(id);
    if (filterData) onRemoveFilter(filterData);
  }

  async function showFilter(id) {
    const filterData = await getFilter(id);
    if (filterData) onShowFilter(filterData);
  }

  // Actualiza el componente al renderizarlo con la lista de filtros
  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  // ---------- //
  return (
    <div>
      <h2>Filtros</h2>
      {filters.length > 0 && (
        <div>
          {filters.map((filter, index) => (
            <div key={index}>
              <label>{filter.name}</label>
              <button onClick={() => showFilter(filter.id)}>Ver</button>
              <button onClick={() => applyFilter(filter.id)}>Aplicar</button>
              <button onClick={() => removeFilter(filter.id)}>Quitar</button>
              <button onClick={() => updateFilter(filter.id)}>Modificar</button>
              <button onClick={() => deleteFilter(filter.id)}>Borrar</button>
            </div>
          ))}
        </div>
      )}
      <button onClick={saveFilter}>Guardar filtro</button>
    </div>
  );
};

export default FiltersList;

import React, { useState } from 'react';
import Navbar from './components/Navbar';
import DisplayTable from './components/DisplayTable';

export default function App() {
  // Navbar States
  const [tableName, setTableName] = useState("base");
  const [isActive, setActive] = useState(false);
  const [isLoading, setLoading] = useState(false);

  return (
    <div className='App'>
      <Navbar
        table={tableName}
        active={isActive}
        onUpdateTable={setTableName}
        onUpdateActivity={setActive}
        onUpdateLoading={setLoading}
      />

      {isActive && (
        <DisplayTable
          table={tableName}
          active={isActive}
          onUpdateLoading={setLoading}
        />
      )}

      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>"Generando datos"</h2>
            <p>Este proceso puede tardar varios minutos</p>
          </div>
        </div>
      )}
    </div>
  );
};

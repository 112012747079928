import React, { useEffect } from "react";
import "../css/Navbar.css";

const URL = process.env.REACT_APP_API_URL;

export default function Navbar({
  table,
  active,
  onUpdateTable,
  onUpdateActivity,
  onUpdateLoading,
}) {
  // Deletes old tables
  async function reset() {
    onUpdateLoading(true);
    try {
      const res = await fetch(`${URL}/tables/reset`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(res.ok ? "Deleted tables" : "Error deleting tables");
      onUpdateActivity(false);
      onUpdateTable("base");
    } catch (error) {
      console.error("Error restarting: ", error);
    } finally {
      onUpdateLoading(false);
    }
  }

  // Creates a new table to work with
  async function start() {
    if (table !== "base") {
      onUpdateLoading(true);
      try {
        const res = await fetch(`${URL}/tables/start/${table}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log(res.ok ? "Created table" : "Error creating table");
        onUpdateActivity(true);
      } catch (error) {
        console.error("Error starting: ", error);
        onUpdateActivity(false);
      } finally {
        onUpdateLoading(false);
      }
    }
  }

  // Only execute when rendering component
  useEffect(() => {
    reset(); // eslint-disable-next-line
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-title">Loto App</div>

      <div className="navbar-dropdown">
        <select
          className="navbar-select"
          value={table}
          onChange={(event) => onUpdateTable(event.target.value)}
          disabled={active}
        >
          <option className="placeholder" value="base" disabled>
            Elige una opción...
          </option>
          <option value="Historial">Historial</option>
          <option value="Combinada">Combinada</option>
        </select>
      </div>

      <div className="navbar-buttons">
        <button
          disabled={table === "base"}
          className={active ? "navbar-button-reset" : "navbar-button-start"}
          onClick={active ? reset : start}
        >
          {active ? "Reset" : "Inicio"}
        </button>
      </div>
    </nav>
  );
}
